import React, { Fragment } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import FooterLogos from './FooterLogos';
import './_index.scss';
import langUSA from '../../img/ico_lang-usa.svg';
import langCa from '../../img/ico_lang-ca.svg';
import icoPayments from '../../img/ico_payments.svg';
import LazyImage from '../LazyImage';
import langArWebp from '../../img/ico_lang-ar.webp';
import langArPng from '../../img/ico_lang-ar.png';

const Footer = () => (
  <Fragment>
    <FooterLogos />
    <footer className="section footer">
      <div className="container">
        <div className="footer__row footer__row--column-4">
          <div className="footer__cell">
            <div className="footer__label">公司</div>
            <ul>
              <li>
                <a href="//www.customwritings.com/about-us.html" rel="nofollow">
                  关于我们
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/contact-us.html" rel="nofollow">
                  联系我们
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/our-services.html" rel="nofollow">
                  我们的服务
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/progressive-delivery.html" rel="nofollow">
                  准时性
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__cell">
            <div className="footer__label">资源</div>
            <ul>
              <li>
                <a href="//www.customwritings.com/our-essay-samples.html">
                  网站范文
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/howtowrite/free-essay-tools/">
                  免费文章写作工具
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/essay-writing-app.html">
                  论文写作应用
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/blog/">
                  博客
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/frequently-asked-questions.html" rel="nofollow">
                  常见问题解答
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__cell">
            <div className="footer__label">条款</div>
            <ul>
              <li>
                <a href="//www.customwritings.com/terms-conditions.html" rel="nofollow">
                  条款和条件
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/cookie-policy.html" rel="nofollow">
                  Cookie政策
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/privacy-policy.html" rel="nofollow">
                  保密政策
                </a>
              </li>
              <li>
                <a href="//www.customwritings.com/social-responsibility-notice.html" rel="nofollow">
                  董事对于社会责任的通知
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__cell">
            <div className="footer__langs">
              <div className="footer__label">语言</div>
              <div className="footer__langs__list">
                <a href="https://ca.customwritings.com/">
                  <LazyImage
                    src={langCa}
                  />
                </a>
                <a href="https://customwritings.com/ae">
                  <LazyLoadComponent>
                    <picture>
                      <source
                        srcSet={langArWebp}
                        type="image/webp"
                      />
                      <img
                        src={langArPng}
                        alt="CustomWritings.com/ae"
                        width="35"
                        height="24"
                      />
                    </picture>
                  </LazyLoadComponent>
                </a>
                <a href="https://www.customwritings.com/">
                  <LazyImage
                    src={langUSA}
                  />
                </a>
              </div>
            </div>
            <div className="footer__accept">
              <div className="footer__label">付款方式</div>
              <LazyImage src={icoPayments} />
            </div>
          </div>
        </div>
        <div className="footer__row footer__row--column-2">
          <div className="footer__cell footer__cell--attention">
            <div className="footer__label">请注意！</div>
            <p>使用我们提供的服务是合法的，而且不违反校园规则。</p>
            <p>客户可以在以下情况使用我们的原文：</p>
            <ol>
              <li>作为附加了解主题的来源</li>
              <li>作为研究思路，推理的来源（必须正确引用）</li>
              <li>适当的改述原文（请了解学院对抄袭和可接受改述的界限）</li>
              <li>在允许的情况下直接引用</li>
            </ol>
            <p>感谢您重视网站作家的版权。<br />网站管理员</p>
          </div>
          <div className="footer__cell">
            <div className="footer__cell--disclaimer">
              <div className="footer__label">免责声明：</div>
              {/* eslint-disable-next-line max-len */}
              <p>Writera Limited：定制写作服务提供的在线完成的定制写作文章，包括学期论文，报告研究，毕业论文，学术论文以及其他定制写作服务属于研究材料，只能为学术辅助材料使用。定制写作作品作为参考资料。</p>
            </div>
            <div className="footer__cell--cookie">
              <div className="footer__label">Cookie政策</div>
              <p>
                通过Cookie，您可以更方便体验我们网站的服务。在使用网站期间，
                {' '}
                <a
                  rel="nofollow"
                  href="https://www.customwritings.com/cookie-policy.html"
                >您同意使用cookies
                </a>。
                请您阅读Cookie政策了解更多详情，包括如何改变选项。
              </p>
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          <p>&copy; 2008&mdash; {new Date().getFullYear()} Writera Limited. 版权所有。</p>
          <a
            href="https://www.dmca.com/Protection/Status.aspx?ID=35fde21e-6aab-49cc-bed4-2d85382a0b27"
            title="DMCA"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              src="//images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=35fde21e-6aab-49cc-bed4-2d85382a0b27"
              alt="DMCA.com"
            />
          </a>
        </div>
      </div>
    </footer>
  </Fragment>
);

export default Footer;
